<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <b-modal
        :id="`modal-status`"
        cancelTitle="Cancel"
        title="Change Blog Status"

        centered
        ok-title="Submit"
        @ok="changeBlogStatus"
    >
      <b-row v-if="selectedBlog">
        <b-col>
          <b-form-group
              class="mb-2 mt-25"
              label="Change Status To"
          >

            <v-select
                v-model="selectedBlog.blogStatus"
                :options="statuses"
                :reduce="name=> name.value"
                :clearable="false"

                dir="ltr"
                label="name"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

    <b-card>
      <b-row class="blog-list-wrapper">
        <b-col md="12">
          <h1>Authors Blogs</h1>
        </b-col>

        <b-col md="3">
          <b-form-group label="Search By" class="blog-search">

          <b-input-group class="input-group-merge">
              <b-form-input
                  id="search-input"
                  v-model="debouncedSearch"
                  placeholder="Search"
              />
              <b-input-group-append
                  class="cursor-pointer"
                  is-text
              >
                <feather-icon
                    icon="SearchIcon"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

        </b-col>
        <b-col class="mb-lg-0 mb-1" md="3">
          <b-form-group label="Select Game" >

          <v-select
              v-if="games"
              v-model="gameId"
              :options="games"
              :reduce="name=> name.id"
              class="bg-white"
              dir="ltr"
              label="name"
              placeholder="select game"
          />
          </b-form-group>

        </b-col>

        <b-col
            md="12"
        >
          <b-table
              v-if="blogList"
              ref="refUserListTable"
              :fields="myTableColumns"
              :items="blogList"
              bordered
              class="position-relative"
              empty-text="Nothing found !"
              primary-key="id"
              responsive
              show-empty
              sort-by.sync
              striped
          >

            <template #cell(blogStatus)="data">
              <b-badge
                  :variant="data.item.blogStatusId===1?'secondary':data.item.blogStatusId===2?'success':data.item.blogStatusId===3?'danger':''"
                  class="  mx-25 cursor-pointer"
                  pill
                  @click="openModal(data.item)"

              >
                {{
                  data.item.blogStatusId === 1 ? 'pending' : data.item.blogStatusId === 2 ? 'published' : data.item.blogStatusId === 3 ? 'unpublished' : ''
                }}
                <feather-icon icon="ChevronDownIcon"></feather-icon>

              </b-badge>
            </template>
            <template #cell(createdAt)="data">
              <span>{{
                  new Date(data.item.createdAt).toLocaleDateString('en-US')
                }} - {{ new Date(data.item.createdAt).toLocaleTimeString('en-US') }}</span>
            </template>
            <template #cell(operation)="data">
              <feather-icon v-b-modal.modal-delete class="text-danger" icon="TrashIcon" size="20" @click="setSelectedBlog(data.item)"
              ></feather-icon>

              <b-link
                  :to="{ name: 'pages-blog-edit', params: { id: data.item.blogId } }"
                  class="font-weight-bold"
              >
                <feather-icon class="text-primary ml-1" icon="EditIcon" size="20"></feather-icon>

              </b-link>
            </template>

          </b-table>

        </b-col>
        <b-col cols="12">
          <!-- pagination -->
          <div class="my-2">
            <b-pagination
                v-model="currentPage"
                :per-page="8"
                :total-rows="totalCount"
                align="center"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>

    </b-card>
    <!-- blogs -->

    <!--/ blogs -->

    <!-- sidebar -->
    <!--/ sidebar -->

    <b-modal
        id="modal-delete"
        cancelTitle="Cancel"
        centered
        title="Delete Blog"
        ok-title="Delete"
        @ok="deleteBlog"
    >
      <span>Are you sure?</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import { BlogDeleteRequest, BlogGetAllForAdminRequest, ChangeBlogStatus } from '@/libs/Api/Blog'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { GetAllGames } from '@/libs/Api/games'
import BlogCard from '@/views/components/blog/BlogCard.vue'
import vSelect from 'vue-select'

export default {
  title: 'Blogs List',
  data() {
    return {
      searchQuery: '',
      overlay: false,
      blogList: null,
      selectedBlog: null,
      games: null,
      currentPage: 1,
      totalCount: null,
      gameId: null,
      timeout: null,
      statuses: [
        {
          name: 'Pre Publish',
          value: 'PrePublish'
          //1
        },
        {
          name: 'Published',
          value: 'Published'
          //2
        },
        {
          name: 'Un Publish',
          value: 'UnPublish'
          //3
        },
      ],
      myTableColumns: [
        {
          key: 'blogId',
          label: 'ID',
          sortable: true
        },
        {
          key: 'title',
          label: 'title',
          sortable: true
        },
        {
          key: 'bloggerName',
          label: 'Blogger Name',
          sortable: true
        },
        {
          key: 'gameName',
          label: 'game name',
          sortable: true
        },
        {
          key: 'blogStatus',
          label: 'status',
          sortable: true
        },
        {
          key: 'createdAt',
          label: 'create date'
        },
        {
          key: 'operation',
          label: 'operation'
        },
      ]

    }
  },
  async created() {
    await this.getBlogs()
    await this.getGames()
  },
  computed: {
    debouncedSearch: {
      get() {
        return this.searchQuery
      },
      set(val) {

        if (this.timeout) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchQuery = val
        }, 600)

      }
    }
  },
  methods: {
    openModal(item) {
      this.selectedBlog = item
      this.$bvModal.show(`modal-status`)
    },
    setSelectedBlog(item) {
      this.selectedBlog = JSON.parse(JSON.stringify(item))
    },
    async changeBlogStatus() {
      let _this = this
      _this.overlay = true
      let changeBlogStatus = new ChangeBlogStatus(_this)
      changeBlogStatus.setParams({
        blogId: _this.selectedBlog.blogId,
        status: _this.selectedBlog.blogStatus
      })
      await changeBlogStatus.fetch(function (content) {
            _this.overlay = false
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
                text: `blog status updated`,
              },
            })
            _this.getBlogs()
            this.$bvModal.hide(`modal-status`)

          },
          function (error) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: error.message,
                title: `error`,
                variant: 'danger',
              },
            })
          })
    },
    async getBlogs() {
      let _this = this
      _this.overlay = true

      let getBlogData = {
        pageNumber: _this.currentPage,
        count: 8,
        search: _this.searchQuery,
        gameId: _this.gameId ? _this.gameId : '',
        type: 'Blogger'
      }

      let blogGetAllRequest = new BlogGetAllForAdminRequest(_this)
      blogGetAllRequest.setParams(getBlogData)
      await blogGetAllRequest.fetch(function (content) {
        _this.overlay = false
        _this.blogList = content.data.data
        _this.totalCount = content.data.totalCount
      }, function (content) {
        console.log(content)
      })
    },
    async getGames() {
      let _this = this
      _this.showOverlay = true
      let getAllGames = new GetAllGames(_this)
      let data = {
        pageNumber: 1,
        count: 100,
        search: ''
      }
      getAllGames.setParams(data)
      await getAllGames.fetch(function (content) {
        _this.games = content.data.data
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async deleteBlog() {
      let _this = this
      _this.overlay = true
      let blogDeleteRequest = new BlogDeleteRequest(_this)
      blogDeleteRequest.setParams({ blogId: _this.selectedBlog.blogId })
      await blogDeleteRequest.fetch(function (content) {
            _this.overlay = false
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
                text: `Item deleted.`,
              },
            })
            _this.getBlogs()
          },
          function (error) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: error.message,
                title: `error`,
                variant: 'danger',
              },
            })
          })
    },
    createJalaliDate(param) {
      return new Date(param).toLocaleDateString('fa-IR',)
    },
  },
  components: {
    BlogCard,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BTable,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    vSelect,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    BFormRadio,
    BOverlay,
    BModal,
    ContentWithSidebar,
  },
  watch: {
    currentPage: function (nv, ov) {
      this.getBlogs()
    },
    searchQuery: function (nv, ov) {
      this.getBlogs()
    },
    gameId: function (nv, ov) {
      this.getBlogs()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
