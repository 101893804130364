<template>
  <b-card
      no-body
      tag="article"
  >
    <b-link :to="{ name: 'pages-blog-edit', params: { id: blog.blogId } }">
      <b-img
          :src="blog.imageUrl"
          class="card-img-top"
      />
    </b-link>
    <b-card-body>
      <b-card-title class="d-flex align-items-center justify-content-between">
        <b-link
            :to="{ name: 'pages-blog-edit', params: { id: blog.blogId } }"
            class="blog-title-truncate text-body-heading"
        >
          {{ blog.title }}
        </b-link>
        <b-badge
            :variant="blog.blogStatusId===1?'secondary':blog.blogStatusId===2?'success':blog.blogStatusId===3?'danger':''"
            class="d-flex align-items-center mx-25 cursor-pointer"
            pill
            @click="openModal"
        >
          {{
            blog.blogStatusId === 1 ? 'pending' : blog.blogStatusId === 2 ? 'published' : blog.blogStatusId === 3 ? 'danger' : 'un published'
          }}

        </b-badge>
      </b-card-title>

      <b-card-text class=" mt-1 item-description">
        {{ blog.summary }}
      </b-card-text>
      <hr>
      <div class="d-flex justify-content-between align-items-center">
              <span>
                <div class="d-flex align-items-center text-body">
<!--                  <feather-icon-->
                  <!--                    icon="MessageSquareIcon"-->
                  <!--                    class="mr-50"-->
                  <!--                  />-->
                  <!--                  <span class="font-weight-bold">{{ blog.commentsCount }} نظرات</span>-->
                  <!--                  <span class="text-muted ml-75 mr-50">|</span>-->
                  <feather-icon
                      class="mr-50"
                      icon="EyeIcon"
                  />
                  <span class="font-weight-bold">for {{ blog.gameName }}</span>
                </div>
                 <div class="d-flex text-primary cursor-pointer align-items-center text-body"
                      @click="$router.push('/')"
                 >
<!--    feather-icon   -->

                  <feather-icon
                      class="mr-50 text-primary"
                      icon="UserIcon"
                  />
                  <span class="font-weight-bold text-primary">{{
                      blog.bloggerName
                    }}</span>
                </div>
              </span>
        <div>

        </div>
      </div>
    </b-card-body>
  </b-card>

</template>

<script>

import {
  BAvatar,
  BBadge,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BModal,
  BOverlay,
  BPagination,
  BRow
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import { ChangeBlogStatus } from '@/libs/Api/Blog'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'BlogCard',
  components: {
    BRow,
    BCol,
    BCard,
    vSelect,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    BFormRadio,
    BOverlay,
    BModal,
    ContentWithSidebar,
  },
  data() {
    return {
      blogStatus: '',

    }
  },
  methods: {

  },
  props: {
    blog: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
